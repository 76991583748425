<template>
<v-container row justify-center>
    <v-dialog max-width="600px" persistent scrollable v-model="isShowDetails">
        <v-card>
            <v-card-title class="headline">
                Details: {{details.title}}
            </v-card-title>
            <v-card-text>
            <!-- <v-card-text v-if="hasPermission('ROLE_PAYCONNECT_VIEW_MERCHANT_DETAILS')">     -->
                <v-row v-for="(field, index) of getFields()" :key="index">
                    <v-col cols="12" v-if="isJSON(field[1])">
                        <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-textarea>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly :data-cy="'MODULE-' + convertCamelCase(field[0]).toLowerCase()"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-card-text v-if="hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS')">
                <v-row>
                    <v-flex xs12>
                        <v-text-field
                            label="alternate contact person"
                            data-cy="form-alt-contact-person"
                            v-model="details.item.alternateContactPerson"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="bancnet flat fee"
                            data-cy="form-bancnet-flat-fee"
                            v-model="details.item.bancnetFlatFee"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="branch contact number"
                            data-cy="form-branch-contact-number"
                            v-model="details.item.branchContactNumber"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="branch contact person"
                            data-cy="form-branc-contact-person"
                            v-model="details.item.branchContactPerson"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="branch email address"
                            data-cy="form-branch-email-address"
                            v-model="details.item.branchEmailAddress"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="branch name"
                            data-cy="form-branch-name"
                            v-model="details.item.branchName"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="city"
                            data-cy="form-city"
                            v-model="details.item.city"
                            readonly
                        ></v-text-field>
                    </v-flex>     
                    <v-flex xs12>
                        <v-text-field
                            label="client number"
                            data-cy="form-client-number"
                            v-model="details.item.clientNumber"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="comments"
                            data-cy="form-comments"
                            v-model="details.item.comments"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="company name"
                            data-cy="form-company-name"
                            v-model="details.item.companyName"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="country"
                            data-cy="form-country"
                            v-model="countryJson"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="created by"
                            data-cy="form-created-by"
                            v-model="details.item.createdBy"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="date created"
                            data-cy="form-date-created"
                            v-model="details.item.dateCreated"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="depository bank account"
                            data-cy="form-depository-bank-account"
                            v-model="details.item.depositoryBankAccount"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="depository bank account name"
                            data-cy="form-depository-bank-account-name"
                            v-model="details.item.depositoryBankAccountName"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="depository bank name"
                            data-cy="form-depository-bank-name"
                            v-model="details.item.depositoryBankName"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="discount rate"
                            data-cy="form-discount-rate"
                            v-model="details.item.discountRate"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="distribution of fees"
                            data-cy="form-distribution-of-fees"
                            v-model="details.item.distributionOfFees"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="email"
                            data-cy="form-email"
                            v-model="details.item.email"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="fax number"
                            data-cy="form-fax-number"
                            v-model="details.item.faxNumber"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="fixed fee"
                            data-cy="form-fixed-fee"
                            v-model="details.item.fixedFee"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="has price adjustment"
                            data-cy="form-has-price-adjustment"
                            v-model="details.item.hasPriceAdjustment"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="id"
                            data-cy="form-id"
                            v-model="details.item.id"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="last updated"
                            data-cy="form-last-updated"
                            v-model="details.item.lastUpdated"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="last updated by"
                            data-cy="form-last-updated-by"
                            v-model="details.item.lastUpdatedBy"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="legal form"
                            data-cy="form-legal-form"
                            v-model="details.item.legalForm"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="legal name"
                            data-cy="form-legal-name"
                            v-model="details.item.legalName"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="merchant address"
                            data-cy="form-merchant-address"
                            v-model="details.item.merchantAddress"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="merchant category code"
                            data-cy="form-merchant-category-code"
                            v-model="merchantCategoryCodeJson"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="merchant id"
                            data-cy="form-merchant-id"
                            v-model="details.item.merchantId"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="mobile number"
                            data-cy="form-mobile-number"
                            v-model="details.item.mobileNumber"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="name"
                            data-cy="form-name"
                            v-model="details.item.name"
                            readonly
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            label="pass discount rate"
                            data-cy="form-pass-discount-rate"
                            v-model="details.item.passDiscountRate"
                            readonly
                        ></v-text-field>
                    </v-flex>                                                                                                                          
                </v-row>
            </v-card-text> -->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="close" data-cy="close-button">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapGetters
} from 'vuex';

export default {
    props: {
        isShow: Boolean,
        details: Object
    },
    computed: {
    ...mapGetters([
            'attributes',
            'authorities'
        ]),
    },
    watch: {
        isShow: {
            handler(value) {
                this.isShowDetails = value
            }
        },

        countryJson: function(newValue) {
            try {
                let newFoo = JSON.parse(newValue);
                this.details.item.country = newFoo;
            } catch (err) {
                console.log("seems to be invalid json: " + err);
                // reset:
                this.countryJson = JSON.stringify(this.details.item.country);
            }
        },

        merchantCategoryCodeJson: function(newValue) {
            try {
                let newFoo = JSON.parse(newValue);
                this.details.item.merchantCategoryCode = newFoo;
            } catch (err) {
                console.log("seems to be invalid json: " + err);
                // reset:
                this.merchantCategoryCodeJson = JSON.stringify(this.details.item.merchantCategoryCode);
            }
        },

        deep: true,
    },
    mounted() {
     this.countryJson = JSON.stringify(this.details.item.country);
     this.merchantCategoryCodeJson = JSON.stringify(this.details.item.merchantCategoryCode)
    },
    data() {
        return {

            countryJson: this.details.item.country ?? {},
            merchantCategoryCodeJson: this.details.item.merchantCategoryCode ?? {},
            
            isShowDetails: this.isShow ?? false,
        }
    },
    methods: {

        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        
        getFields() {
            const arrOfArrays = Object.entries(this.details.item);

            const sorted = arrOfArrays.sort((a, b) => {
            return a[0].localeCompare(b[0]);
            });

            return sorted;
        },
        convertCamelCase(string) {
            return string.replace(/([a-z])([A-Z])/g, '$1 $2');
        },
        close() {
            this.isShowDetails = false
            this.$emit('close-dialog', 'details')
        },
        isJSON(value) {
          return Object.prototype.toString.call(value) === '[object Array]' || Object.prototype.toString.call(value) === '[object Object]'
        },
        stringify(value) {
          if(Object.prototype.toString.call(value) === '[object Array]') {
            var items = []
            for(var index in value) {
              if(value[index].role) {
                items.push(value[index].role)
              }
            }
            if(items.length > 0) {
              return items.toString()
            }
            return JSON.stringify(value)
          } else if(Object.prototype.toString.call(value) === '[object Object]') {
            if(value.role) {
              return value.role
            }
            return JSON.stringify(value)
          } else {
            if(value!=null) return value.toString()
          }
        }
    }
}
</script>
